import useDeviceSize from './useDeviceSize';
// Will match any string that contains the sizes (in any order) followed by the class name
export const matchOverrides = (defaultClass, size) => {
    // Sanitize the defaultClass by escaping special regex characters
    const sanitizedClass = defaultClass.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    // See regexPatternMatching.test.ts for examples on what should match and not
    return new RegExp(`^\\$\\$(.*\\+)?${size}(\\+.*)?\\$\\$_${sanitizedClass}$`);
};
// Will recursively combine all the styles as StyleSheet.compose only takes two arguments
const composeMultipleStyles = (styles, classNames) => {
    // Base cases to return the style if there are not two for a composition
    if (classNames.length === 1)
        return styles[classNames[0]];
    return classNames.map((className) => styles[className]);
};
const buildCustomStyleMap = (styles, size) => {
    const styleObj = {};
    // Will get a list of the base styles excluding all overrides
    const defaultKeys = Object.keys(styles).filter((style) => !style.startsWith('$$'));
    defaultKeys.forEach((key) => {
        // Get all overrides for a certain class
        const styleOverrides = Object.keys(styles).filter((style) => style.match(matchOverrides(key, size)));
        // Combine the base style and any overrides to get the computed style
        styleObj[key] = composeMultipleStyles(styles, [key, ...styleOverrides]);
    });
    return styleObj;
};
export default function useResponsiveStyle(styles) {
    return () => {
        const size = useDeviceSize();
        return buildCustomStyleMap(styles, size);
    };
}
