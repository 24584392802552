import { StyleSheet } from 'react-native';
import useResponsiveStyle from './hooks/useResponsiveStyle';
function CreateResponsiveStyle(webStyles, styleOverrides) {
    const overrides = {};
    // Create custom style names based on the device overrides if provided
    if (styleOverrides) {
        Object.entries(styleOverrides).forEach(([key, value]) => {
            Object.entries(value).forEach(([className, value2]) => {
                overrides[`$$${key}$$_${className}`] = value2;
            });
        });
    }
    // Merge the stylesheets example:
    // {
    //   container: {
    //     color: 'red'
    //   },
    //   $$large$$_container: {
    //     color: 'blue'
    //   },
    //   $$extrasmall+small$$_container: {
    //     color: 'green'
    //   },
    // }
    const styles = StyleSheet.create(Object.assign(Object.assign({}, webStyles), overrides));
    return useResponsiveStyle(styles);
}
export default CreateResponsiveStyle;
